import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { CanComponentDeactivate } from '@app/common/security/can-deactivate.guard';
import { UserProfile } from '@app/common/models/user';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { StaticText } from '@app/common/static-text';
import { Subscription } from 'rxjs';
import { SeverityLevel } from '@app/common/models/severity-level';
import { UserService } from '@app/common/security/user.service';
import { DialogService } from '@app/courses/services/dialog-service';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { DiscussionPostOrReplyItem } from '../../models/discussion-postorreply-item';
import { DiscussionService } from '../../services/discussion.service';
import { editorConfig } from '../../models/editorConfig';
import { ModalSimpleService } from '@app/common/components/modal-simple/modal-simple.service';
import { FileSelectDirective, FileDropDirective, FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload'
import { ConditionalExpr } from '@angular/compiler';
import { Attachment } from '../../models/attachment';

@Component({
  selector: 'ebs-wysiwyg',
  templateUrl: './wysiwyg.component.html',
  styleUrls: ['./wysiwyg.component.scss']
})
export class WysiwygComponent implements OnInit, CanComponentDeactivate {
  //NOTE: post details are only passed in if a reply .. used to identify if post or reply (post is always 'new' so nothing to pass in)
  @Input() postDetails: DiscussionPostOrReplyItem;
  @Input() parentIsMyPost = false;
  @Input() editorText: string;
  @Input() buttonText: string;
  @Input() currentUser: UserProfile;
  @Input() courseCode: string;
  @Input() discussionId: string;
  @Input() placeholderText: string;
  @Output() updatePostEvent = new EventEmitter<DiscussionPostOrReplyItem>();
  @Output() addPostEvent = new EventEmitter<DiscussionPostOrReplyItem>();
  @ViewChild('theform', { static: false }) theform: ElementRef;
  postingData = false; showError = false; isOffline: boolean; isIEOrEdge: boolean;
  editorConfig = editorConfig;
  errorMessage: string;
  newPostOrReply: DiscussionPostOrReplyItem;
  notifications: NotificationSummary[];
  gdprMessage = StaticText.gdprMessage; displayMessage: string;
  madeAChange = false; showFullEditor = false; isEditing = false;
  theSubscription: Subscription;
  isDemoUser = false;
  attachments : Array<Attachment> = new Array<Attachment>();

  isPostButtonDisabled: boolean;

  @HostListener('paste', ['$event'])
  onPaste(event) {
    this.notifications = []
    event.preventDefault();
    let copyText = '';

    //fix ie11 bug by checking file list first - blowing up when big image pasted
    if (event.clipboardData) {
      this.checkForImage(event.clipboardData) ? copyText = '' : copyText = (event.originalEvent || event).clipboardData.getData('text/plain')
    } else if (window['clipboardData']) {
      this.checkForImage(window['clipboardData']) ? copyText = '' : copyText = window['clipboardData'].getData('Text'); //IE11
    }

    if (document.queryCommandSupported('insertText')) {
      document.execCommand('insertText', false, copyText)
      if (copyText === '' || copyText === null) {
        this.notifications.push(new NotificationSummary('We are currently unable to accept certain pasted items, such as images.', 'unknown', SeverityLevel.Warning, 'unknown', 0))
      } else {
        this.notifications.push(new NotificationSummary('Please note that any formatting has been removed.', 'unknown', SeverityLevel.Warning, 'unknown', 0))
      }
    } else {
      this.notifications.push(new NotificationSummary('Please note that pasting is not supported in Internet Explorer.', 'unknown', SeverityLevel.Warning, 'unknown', 0))
    }
    this.showError = true;
  }

  constructor(
    private discussionService: DiscussionService,
    private userService: UserService,
    private dialogService: DialogService,
    private onlineStatusService: OnlineStatusService,
    private modalSimpleService: ModalSimpleService,
  ) {
    this.onlineStatusService.isOffline().subscribe(x => {
      this.isOffline = x;
    }, error => {
      this.isOffline = false;
    });

    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
      }
    )
  }

  ngOnInit() {
    this.isIEOrEdge = this.onlineStatusService.isIEOrEdge();
    this.displayMessage = 'Posting as ' + this.userService.getDisplayName(this.currentUser.personalDetails)
    this.currentUser.userAccount.roles.find(x => x.toString() == "Demo") ? this.isDemoUser = true : this.isDemoUser = false;
    this.isPostButtonDisabled = false;
  }

  editing() {
    this.isEditing = true;
  }

  canDeactivate(): boolean {
    if (this.theform == null) {
      return true;
    }
    if (this.isEditing && this.editorText != '' && this.editorText != null) {
      let answer = this.dialogService.confirm('It looks like you may have an unsaved discussion post/reply. If you leave this section before saving your data it will be lost. \n\nAre you sure you want to leave this section?');
      return answer;
    } else {
      return true
    }
  }

  setShowFullEditor() {
    if (this.isDemoUser) {
      this.showDemoUserModal();
      return
    }
    //M.S. - temp
    this.attachments = new Array<Attachment>();

    this.showFullEditor = true;
  }

  sendReply() {
    this.showError = false;
    this.notifications = [];
    this.editorText = this.editorText.replace(/&nbsp;/g, '').replace(/(<p><\/p>)+/g, '').replace(/(\r\n|\n|\r)/gm, "").trim();
    // the above doesn't do things properly so we need to add the following
    this.editorText = this.editorText.replace(/^<p>\s* <\/p>+/g, '')

    // cant be both these can it?
    if (this.editorText == '' && this.editorText == null) {
      this.showError = true;
      this.notifications.push(new NotificationSummary('Please enter some text', 'unknown', SeverityLevel.Warning, 'unknown', 0))
    } else {
      // if offline and replying to own post then reject
      if (this.isOffline && this.parentIsMyPost) {
        this.showError = true;
        this.notifications.push(new NotificationSummary(StaticText.discussionOfflineReplyToOwnPost, 'unknown', SeverityLevel.Warning, 'unknown', 0))
      } else {
        if (this.editorText === '' || this.editorText === null) {
          this.showError = true;
          this.notifications.push(new NotificationSummary('Please enter some text', 'unknown', SeverityLevel.Warning, 'unknown', 0))
        } else {
          if (this.editorText.length > 10000) {
            this.showError = true;
            this.notifications.push(new NotificationSummary('The comment limit is 10000 characters including formatting. Current count is: ' + this.editorText.length, 'unknown', SeverityLevel.Warning, 'unknown', 0))
          } else {
            //if has post details then is a reply
            if (this.postDetails) {
              this.createNewPostOrReply();
              this.newPostOrReply.postId = this.postDetails.id;
              this.newPostOrReply.replies = null;
              this.addReply();
            } else {
              this.createNewPostOrReply();
              this.newPostOrReply.likeCount = 0;
              this.addPost();
            }
          }
        }
      }
    }
  }

  onFileUploadInProgress(isUploading:boolean){
    this.isPostButtonDisabled = isUploading;
    console.log(`File is uploading = ${isUploading}`);
  }

  createNewPostOrReply() {
    this.newPostOrReply = new DiscussionPostOrReplyItem();
    this.newPostOrReply.content = this.editorText
    this.newPostOrReply.isMyEntry = true;
    this.newPostOrReply.discussionId = this.discussionId ? this.discussionId : this.postDetails.discussionId;
    this.newPostOrReply.courseCode = this.courseCode ? this.courseCode : this.postDetails.courseCode;
    this.newPostOrReply.clientModifiedAt = new Date();
    this.newPostOrReply.postId = null;
    this.newPostOrReply.id = null;
    this.newPostOrReply.attachments = this.attachments;
    // this.attachments = new Array<Attachment>();
    // this.newPostOrReply.attachments = new Array<Attachment>();
  }

  addReply() {
    // we update the reply in the DB then we already have the post details so we update this
    // (ie add to the replies collection) and pass up to top level to handle indexeddb
    this.resetBeforeRemoteCall();
    this.discussionService.addReply(this.newPostOrReply, this.isOffline).subscribe(
      response => {
        if (!response.operationSucceeded) {
          this.postingData = false;
          this.showError = true;
          this.notifications = response.notifications;
        } else {
          this.postingData = false;
          this.newPostOrReply.person = response.data.person
          // emit updated post here
          this.newPostOrReply.id = response.data.id;
          this.postDetails.replies.push(this.newPostOrReply);
          this.updatePostEvent.emit(this.postDetails);
        }
      },
      error => {
        this.postingData = false;
        this.notifications.push(new NotificationSummary(error, 'unknown', SeverityLevel.Error, 'unknown', 0))
        this.showError = true;
      }
    );
  }

  addPost() {
    this.resetBeforeRemoteCall();
    this.discussionService.addPost(this.newPostOrReply, this.isOffline).subscribe(
      response => {
        if (!response.operationSucceeded) {
          this.postingData = false;
          this.showError = true;
          this.notifications = response.notifications;
        } else {
          this.postingData = false;
          this.editorText = '';
          this.showFullEditor = false;
          // we need the post ID
          this.newPostOrReply.person = response.data.person
          this.newPostOrReply.id = response.data.id;
          //emit updated post
          this.addPostEvent.emit(this.newPostOrReply)
        }
      },
      error => {
        this.postingData = false;
        this.showError = true;
        this.notifications.push(new NotificationSummary(error, 'unknown', SeverityLevel.Error, 'unknown', 0))
      }
    );
  }

  resetBeforeRemoteCall() {
    this.showError = false;
    this.errorMessage = '';
    this.postingData = true;
    this.notifications = [];
  }

  checkForImage(clipboardData: DataTransfer): boolean {
    //contains a files collection - this is only for files (eg an image) that is pasted in... cant foreach on this
    let fileCollection = clipboardData.files;
    if (fileCollection && fileCollection.length > 0) {
      for (let i = 0; i < fileCollection.length; i++) {
        if (fileCollection[i].type.indexOf('image') > -1) {
          return true;
        }
      }
    }
    return false;
  }

  showDemoUserModal() {
    this.modalSimpleService.confirm(
      StaticText.demoUserModalHeader,
      StaticText.demoUserModalBody,
      [{ buttonText: 'OK', buttonStyle: 'btn btn-primary btn-block' }])
      .subscribe(
        //nothing to do here 
      );
  }


}
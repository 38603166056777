//default edtiro
export const editorConfig = {
  base_url: '/tinymce', // Root for resources
  menubar: false,
  suffix: '.min',
  statusbar: true,
  elementpath: false,
  selector: "textarea",
  browser_spellcheck: true,
  plugins: "link lists paste autolink",
  toolbar: 'bold italic underline | bullist numlist link',
  contextmenu: false,
  branding: false,
  link_title: false,
  target_list: false,
  paste_word_valid_elements: "ul,li,a[href],b,i,strong,p,u,ol",
  remove_linebreaks: false,
  default_link_target: "_blank",
  inline_styles: false,
  formats: {
    underline: { inline: 'u', exact: true }
  },
  setup: function (editor) {
    editor.on('init', function (e) {
      editor.focus();
    });
  },
  paste_preprocess: function (plugin, args) {
    // .replace(/(\r\n|\n|\r)/gm, "")
    let textWithFormatting = args.content.replace(/&nbsp;/g, '').replace(/(<p><\/p>)+/g, '').replace(/(\r\n|\n|\r)/gm, "").trim();
    args.content = textWithFormatting;
  },
};

//config for essay editor
export const essayEditorConfig = {
  base_url: '/tinymce', // Root for resources
  menubar: false,
  suffix: '.min',
  statusbar: true,
  elementpath: false,
  selector: "textarea",
  browser_spellcheck: true,
  plugins: "link lists paste autolink",
  toolbar: 'bold italic underline | bullist numlist link',
  contextmenu: false,
  branding: false,
  link_title: false,
  target_list: false,
  paste_word_valid_elements: "ul,li,a[href],b,i,strong,p,u,ol",
  remove_linebreaks: false,
  default_link_target: "_blank",
  inline_styles: false,
  formats: {
    underline: { inline: 'u', exact: true }
  },
  paste_preprocess: function (plugin, args) {
    let textWithFormatting = args.content.replace(/&nbsp;/g, '').replace(/(<p><\/p>)+/g, '').replace(/(\r\n|\n|\r)/gm, "").trim();
    args.content = textWithFormatting;
  },
};


export const ForumEditorConfig = {
  base_url: '/tinymce', // Root for resources
  menubar: false,
  suffix: '.min',
  statusbar: false,
  selector: "textarea",
  browser_spellcheck: true,
  plugins: "link lists paste autolink",
  toolbar: 'bold italic underline | bullist numlist link',
  contextmenu: false,
  branding: false,
  link_title: false,
  target_list: false,
  paste_word_valid_elements: "ul,li,a[href],b,i,strong,p,u,ol",
  remove_linebreaks: false,
  default_link_target: "_blank",
  inline_styles: false,
  relative_urls: false,
  
  formats: {
    underline: { inline: 'u', exact: true }
  },
  paste_preprocess: function (plugin, args) {
    let textWithFormatting = args.content.replace(/&nbsp;/g, '').replace(/(<p><\/p>)+/g, '').replace(/(\r\n|\n|\r)/gm, "").trim();
    args.content = textWithFormatting;
  }

};


